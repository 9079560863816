import { createPermissionChecker, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'customers.create',
        items: [],
    },
    {
        routeName: 'customers.details',
        items: [
            {
                title: 'manage communities',
                routeName: 'customer.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
            {
                title: 'manage users',
                routeName: 'customer.users_mngmnt.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            },
        ],
    },
    //Deactivated customer
    {
        routeName: 'deletedCustomers.details',
        items: [
            {
                title: 'view communities',
                routeName: 'deletedCustomer.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
        ],
    },
    // Communities
    {
        routeName: 'customer.communities.index',
        items: [],
    },
    {
        routeName: 'customer.communities.details',
        items: [],
    },
    // Users
    {
        routeName: 'customer.users.index',
        items: [],
    },
    {
        routeName: 'customer.users.details',
        items: [],
    },
    {
        routeName: 'customer.users.create',
        items: [],
    },
    {
        routeName: 'customer.deletedUsers.details',
        items: [],
    },
    // Admins
    {
        routeName: 'customer.admins.index',
        items: [],
    },
    {
        routeName: 'customer.admins.details',
        items: [],
    },
    {
        routeName: 'customer.admins.create',
        items: [],
    },
    // Roles
    {
        routeName: 'customer.roles.index',
        items: [],
    },
];
