import store from '@/store';
import { useContextParams } from '@/stores/auth/contextParams';
import { ADMIN_GROUP_CATEGORY } from '@/components/auth/permissions/constants';
import { AUTH_APP_ID } from '@/components/auth/constants';

export const createPermissionChecker = permissionName => () => {
    const { context } = useContextParams();

    const customerId = context.customerId || store.getters['auth/customerId'];
    const communityId = context.communityId || store.getters['auth/community']?.id;

    return store.getters['auth/getAuthPermissions'](permissionName, customerId, communityId)?.value === 'true';
};

export const createAppAccessChecker = appCode => () => store.getters['auth/profileAppCodes'].includes(appCode);

export const isAdminPermission = permission => permission.appId === AUTH_APP_ID && ADMIN_GROUP_CATEGORY.has(permission.category);

export const getPermissionTooltip = (item, isInternal) => {
    if (isInternal) {
        return 'A permission of an internal role can not be modified';
    }

    if (isAdminPermission(item)) {
        return 'A permission of admin level can not be assigned to a portfolio user';
    }

    return null;
};
