import { intersection } from 'lodash-es';

const userRole = state => state.user.activeProfile?.role;

const authenticated = state => state.user.authenticated;

const profile = state => state.user.activeProfile;

const profiles = state => state.user.profiles;

const getAppPermissions = state => appId => state.user.appsPermissions[appId];

const appsPermissions = state => state.user.appsPermissions;

const isPortfolioUser = state => state.user.activeProfile?.type === 'PORTFOLIO';

const isSuperAdmin = state => state.user.activeProfile?.type === 'SUPER_ADMIN';

const isQuextUser = state => state.user.activeProfile?.type === 'QUEXT';

// a fallback for the old role-based approach
const isQuext = state =>
    isSuperAdmin(state) || isQuextUser(state) || ['SUPER_READONLY_ADMIN', 'DIGITAL_HUMAN_WRITER'].includes(state.user.activeProfile?.role);

// a fallback for the old role-based approach
const isQuextAdmins = state => isSuperAdmin(state) || state.user.activeProfile?.role === 'SUPER_READONLY_ADMIN';

const email = state => state.user.email;

const customerId = state => state.user.activeProfile?.customerId;

const cognitoUserId = state => state.user.username;

const community = state => state.user.activeCommunity;

const profileAppCodes = state => state.user.activeProfile?.appCodes || [];

const customerApps = state => state.user.activeCustomerApps || [];

const customerAppCodes = state => customerApps(state).map(app => app.appCode);

const customerCommunityAppCodes = state => state.user.activeCustomerCommunityAppCodes;

const communities = state => state.communities;

const profilePermissions = state => state.user.activeProfile?.authPermissions || [];

const getAuthPermissions = state => (permissionName, contextCustomerId, contextCommunityId) => {
    const { noContextPermissions = [], customerContextPermissions = [] } = profilePermissions(state);

    const noContextPermission = noContextPermissions.find(({ permissionKey }) => permissionKey === permissionName);

    if (noContextPermission) {
        return noContextPermission;
    }

    if (contextCustomerId) {
        const customerContext = customerContextPermissions.find(({ customerId }) => customerId === contextCustomerId);

        const contextCustomerPermission = customerContext?.customerContextPermissions.find(
            ({ permissionKey }) => permissionKey === permissionName
        );

        if (contextCustomerPermission) {
            return contextCustomerPermission;
        }

        if (customerContext && contextCommunityId) {
            const communityContext = customerContext.communities.find(({ communityId }) => communityId === contextCommunityId);

            return communityContext?.communityContextPermissions.find(({ permissionKey }) => permissionKey === permissionName);
        }
    }
};

const appAccesses = state => {
    const initCodes = profileAppCodes(state);
    const hasCustomerContext = (isSuperAdmin(state) || isQuextUser(state)) && customerId(state);

    const customerAccess = hasCustomerContext ? intersection(initCodes, customerAppCodes(state)) : initCodes;

    return community(state) ? intersection(customerAccess, customerCommunityAppCodes(state)) : customerAccess;
};

const hasAuthAccess = state => profileAppCodes(state).includes('AUTH');

export default {
    userRole,
    authenticated,
    profile,
    profiles,
    getAppPermissions,
    appsPermissions,
    isQuext,
    isQuextAdmins, // should be deprecated
    isSuperAdmin,
    isQuextUser,
    isPortfolioUser,
    email,
    customerId,
    cognitoUserId,
    community,
    communities,
    customerAppCodes,
    customerCommunityAppCodes,
    profileAppCodes,
    profilePermissions,
    getAuthPermissions,
    appAccesses,
    hasAuthAccess,
};
