export const resources = {
    quextUsers: '/profiles/quext-users',
    quextUsersAccess: {
        block: ['POST', '/profiles/quext-users/{profileId}/deactivate'],
        restore: ['POST', '/profiles/quext-users/{profileId}/reactivate'],
        reinvite: ['POST', '/profiles/quext-users/{profileId}/re-invite'],
        resetMfa: ['POST', '/profiles/quext-users/{profileId}/reset-mfa'],
        resetPassword: ['POST', '/profiles/quext-users/{profileId}/reset-password'],
    },
    apps: '/apps',
    allApps: '/apps/all',
    appEndpointStatus: '/apps/api-endpoint-status',
    appCustomers: '/apps/{appId}/accesses',
    appCustomerUsers: '/apps/{appId}/accesses/{appAccessId}/profiles',
    appCustomersRevoke: '/apps/{appId}/accesses/{appAccessId}/revoke',
    appCustomersRestore: '/apps/{appId}/accesses/{appAccessId}/restore',
    appPermissions: '/apps/{appId}/permissions',
    appPermissionActions: {
        cleanup: ['POST', '/apps/{appId}/permissions/clean-up'],
    },
    appPermissionJson: {
        get: ['GET', '/apps/{appId}/permissions/{permissionId}/json'],
    },
    appRoles: '/apps/{appId}/roles',
    appSync: '/apps/{appId}/sync',
    appSyncResult: '/apps/syncs/{syncId}',
    appSyncPermissions: {
        sync: ['POST', '/apps/{appId}/role-permission-sync'],
    },
    bundles: '/bundles',
    bundleUsage: '/bundles/{bundleId}/usages',
    bundleMetaroleUsage: 'bundles/{bundleId}/metaroles/{metaroleId}/customer-profiles',
    customers: '/customers',
    communities: '/communities',
    communitiesImport: {
        create: ['POST', '/customers/{customerId}/import-communities'],
    },
    communitiesValidateImportFile: {
        create: ['POST', '/customers/{customerId}/import-communities/validate-file'],
    },
    communitiesImportStatus: {
        get: ['GET', '/customers/{customerId}/import-communities/{importId}'],
    },
    communitiesImportTemplate: {
        get: ['GET', '/import-communities/download-example-file'],
    },
    communityLicensedAppAccess: {
        get: ['GET', '/customers/{customerId}/communities/{communityId}/app-accesses'],
    },
    profileCommunities: {
        getList: ['GET', '/customers/{customerId}/profile-communities'],
    },
    profileCommunitiesLicensing: {
        update: ['POST', '/customers/{customerId}/profile-licensed-communities'],
    },
    customerCommunities: '/customers/{customerId}/communities',
    customerApps: '/customers/{customerId}/authorized-apps',
    customerRoles: '/customers/{customerId}/roles',
    customerUsers: '/customers/{customerId}/customer-users',
    portfolioAdmins: '/customers/{customerId}/portfolio-admin-profiles',
    portfolioAdminAccess: {
        block: ['POST', '/customers/{customerId}/portfolio-admin-profiles/{profileId}/deactivate'],
        restore: ['POST', '/customers/{customerId}/portfolio-admin-profiles/{profileId}/reactivate'],
        reinvite: ['POST', '/customers/{customerId}/portfolio-admin-profiles/{profileId}/re-invite'],
        resetMfa: ['POST', '/customers/{customerId}/portfolio-admin-profiles/{profileId}/reset-mfa'],
        resetPassword: ['POST', '/customers/{customerId}/portfolio-admin-profiles/{profileId}/reset-password'],
    },
    portfolioUsers: '/customers/{customerId}/portfolio-profiles',
    communityPortfolioUsers: '/customers/{customerId}/communities/{communityId}/portfolio-profiles',
    portfolioUsersByEmailOrPhone: {
        get: ['POST', '/customers/{customerId}/portfolio-profiles/contacts'],
    },
    portfolioUserAccess: {
        block: ['POST', '/customers/{customerId}/portfolio-profiles/{profileId}/deactivate'],
        restore: ['POST', '/customers/{customerId}/portfolio-profiles/{profileId}/reactivate'],
        reinvite: ['POST', '/customers/{customerId}/portfolio-profiles/{profileId}/re-invite'],
        resetMfa: ['POST', '/customers/{customerId}/portfolio-profiles/{profileId}/reset-mfa'],
        resetPassword: ['POST', '/customers/{customerId}/portfolio-profiles/{profileId}/reset-password'],
    },
    portfolioRoles: '/portfolio-roles/base-configurations',
    customPortfolioRoles: '/customers/{customerId}/customer-role-configurations',
    customPortfolioCommunityRoles: '/customers/{customerId}/communities/{communityId}/community-role-configurations',
    customPortfolioUserRoleState:
        '/customers/{customerId}/communities/{communityId}/portfolio-profiles/{profileId}/apps/{appId}/user-role-state',
    metaroles: '/metarole-templates',
    metaroleUsage: {
        getList: ['GET', '/metarole-templates/{metaroleId}/usages'],
    },
    customerAccesses: '/customers/{customerId}/accesses',
    customerAppAccesses: '/customers/{customerId}/app-accesses',
    customerLicensing: {
        update: ['POST', '/customers/{customerId}/licensed-communities'],
    },
    customerPermissions: '/customers/{customerId}/permissions',
    customerRoleDetails: '/customers/{customerId}/roles/{roleId}',
    quextPresets: '/quext-presets',
    portfolioPresets: '/portfolio-presets',
    quextPresetPermissions: {
        getList: ['GET', '/quext-presets/permissions'],
    },
    presetPermissions: {
        getList: ['GET', '/portfolio-presets/permissions'],
    },
    permissionGroups: '/permission-groups',
    permissionGroupReplacement: {
        replace: ['POST', '/permission-groups/{groupId}/replace'],
    },
    customerPermissionGroups: '/customers/{customerId}/permission-groups',
    communityPermissionGroups: '/customers/{customerId}/communities/{communityId}/permission-groups',
    superAdmins: '/profiles/super-admins',
    superAdminAccess: {
        block: ['POST', '/profiles/super-admins/{profileId}/deactivate'],
        restore: ['POST', '/profiles/super-admins/{profileId}/reactivate'],
        reinvite: ['POST', '/profiles/super-admins/{profileId}/re-invite'],
        resetMfa: ['POST', '/profiles/super-admins/{profileId}/reset-mfa'],
        resetPassword: ['POST', '/profiles/super-admins/{profileId}/reset-password'],
    },
    identities: {
        getList: ['GET', '/identities'],
    },
    profile: '/profile',
    timezones: '/timezones',
    userCommunities: '/my/communities',
    managementRoles: '/communities/management-roles',
    tourSchedulingTypes: '/communities/tour-scheduling-types',
    weekdays: '/communities/weekdays',
};
