import { createAppAccessChecker } from '@/components/auth/permissions';

export default [
    {
        path: '/raa-tools/persons',
        name: 'raaTools.persons.index',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/persons/PersonsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'persons',
            title: 'persons',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/persons/create',
        name: 'raaTools.persons.create',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/persons/PersonCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new person',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/persons/:personId/reinvite',
        name: 'raaTools.persons.invite.index',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/persons/PersonReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/persons/:personId',
        name: 'raaTools.persons.edit',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/persons/PersonEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit person',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/memberships',
        name: 'raaTools.memberships.index',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/memberships/MembershipsList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'memberships',
            title: 'memberships',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/memberships/create',
        name: 'raaTools.memberships.create',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/memberships/MembershipCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new membership',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/memberships/:membershipId',
        name: 'raaTools.memberships.edit',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/memberships/MembershipEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit membership',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/parties',
        name: 'raaTools.parties.index',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/parties/PartiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'parties',
            title: 'parties',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/parties/create',
        name: 'raaTools.parties.create',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/parties/PartyCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new party',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/parties/:partyId',
        name: 'raaTools.parties.edit',
        component: () => import(/* webpackChunkName: "raaLocalData" */ '@/views/auth/raa-devtools/parties/PartyEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit party',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/applications',
        name: 'raaTools.applications.index',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/applications/ApplicationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'applications',
            title: 'applications',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/applications/:code/details',
        name: 'raaTools.applications.details',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/applications/ApplicationDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'application details',
            title: 'application',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/applications/create',
        name: 'raaTools.applications.create',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/applications/ApplicationCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new application',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/applications/:code',
        name: 'raaTools.applications.edit',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/applications/ApplicationEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit application',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles',
        name: 'raaTools.profiles.index',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/ProfilesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'profiles',
            title: 'profiles',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/templates',
        name: 'raaTools.profiles.templates.index',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/templates/ProfileTemplatesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'templates',
            title: 'profile templates',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/templates/create',
        name: 'raaTools.profiles.templates.create',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/templates/ProfileTemplateCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new template',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/templates/:id',
        name: 'raaTools.profiles.templates.edit',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/templates/ProfileTemplateEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit template',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/shared-data-blocks',
        name: 'raaTools.profiles.dataBlocks.create',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add data block',
            title: 'add new data block',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/shared-data-blocks/:name/version/:version/clone',
        name: 'raaTools.profiles.dataBlocks.clone',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'duplicate',
            title: 'duplicate data block',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/shared-data-blocks/:name/version/:version',
        name: 'raaTools.profiles.dataBlocks.edit',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileDataEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit data block',
            title: 'edit data block',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/:profileId/details',
        name: 'raaTools.profiles.details',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/ProfileDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'profile details',
            title: 'profile details',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/create',
        name: 'raaTools.profiles.create',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/ProfileCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new profile',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/profiles/:profileId',
        name: 'raaTools.profiles.edit',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/profiles/ProfileEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit profile',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/identities',
        name: 'raaTools.identities.index',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/identities/IdentitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'identities',
            title: 'identities',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/identities/:identityId/details',
        name: 'raaTools.identities.details',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/identities/IdentityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'identity details',
            title: 'identity details',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/identities/create',
        name: 'raaTools.identities.create',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/identities/IdentityCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new identity',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/identities/:identityId',
        name: 'raaTools.identities.edit',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/identities/IdentityEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit identity',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/shared-data-keys',
        name: 'raaTools.sharedDataKeys.index',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/shared-data-keys/SharedDataKeysList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'shared data keys',
            title: 'shared data keys',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/shared-data-keys/create',
        name: 'raaTools.sharedDataKeys.create',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/shared-data-keys/SharedDataKeyCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new shared data key',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/shared-data-keys/:id',
        name: 'raaTools.sharedDataKeys.edit',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/shared-data-keys/SharedDataKeyEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit shared data key',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/relationship-types',
        name: 'raaTools.relationshipTypes.index',
        component: () => import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/relationship-types/RelationshipTypesList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'relationship types',
            title: 'relationship types',
            product: 'admin',
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details',
        name: 'raaTools.relationshipTypes.details',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/relationship-types/RelationshipTypeDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'details',
            title: 'relationship type',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details/data-block/create',
        name: 'raaTools.relationshipTypes.dataBlock.create',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/relationship-types/data-block/DataBlockCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new data block',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details/data-block/:name/version/:version/clone',
        name: 'raaTools.relationshipTypes.dataBlock.clone',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/relationship-types/data-block/DataBlockCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'duplicate',
            title: 'duplicate data block',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
    {
        path: '/raa-tools/relationship-types/:type/details/data-block/:name/version/:version',
        name: 'raaTools.relationshipTypes.dataBlock.edit',
        component: () =>
            import(/* webpackChunkName: "raaDevTools" */ '@/views/auth/raa-devtools/relationship-types/data-block/DataBlockEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit data block',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('RAA'),
        },
    },
];
