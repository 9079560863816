<template>
    <div
        class="breadcrumbs"
        :class="{ 'breadcrumbs--collapsed': needToCollapse }"
    >
        <modal-link route="home">
            <slot name="home-icon">
                <icon
                    :name="homeIcon"
                    class="w-4 h-4"
                />
            </slot>
        </modal-link>

        <div
            v-if="firstModal"
            class="breadcrumbs__item"
            :class="breadcrumbsItemClasses"
        >
            <span class="w-4 h-4 mx-1"><icon
                name="chevronRight"
                class="h-4 w-4"
            /></span>
            <router-link
                class="breadcrumbs__link hover:text-white whitespace-no-wrap"
                :class="breadcrumbsLinkClasses"
                :to="{ name: firstModal.routeName, params: firstModal.params }"
                :title="firstModal.breadcrumbName"
                @click="closeModalPopupComponents"
            >
                {{ firstModal.breadcrumbName }}
            </router-link>
        </div>

        <template v-if="otherModals.length > 0">
            <template v-if="!needToCollapse">
                <div
                    v-for="modal in otherModals"
                    :key="modal.routeName"
                    class="breadcrumbs__item overflow-hidden"
                    :class="breadcrumbsItemClasses"
                >
                    <span class="w-4 h-4 mx-1"><icon
                        name="chevronRight"
                        class="h-4 w-4"
                    /></span>
                    <router-link
                        class="breadcrumbs__link hover:text-white truncate"
                        :class="breadcrumbsLinkClasses"
                        :to="{ name: modal.routeName, params: modal.params }"
                        :title="modal.breadcrumbName"
                        @click="closeModalPopupComponents"
                    >
                        {{ modal.breadcrumbName }}
                    </router-link>
                </div>
            </template>

            <div
                v-else
                class="breadcrumbs__item"
                :class="breadcrumbsItemClasses"
            >
                <span class="w-4 h-4 mx-1"><icon
                    name="chevronRight"
                    class="h-4 w-4"
                /></span>
                <drop-menu ref="dropMenu">
                    <template #header>
                        <div
                            class="breadcrumbs__link hover:text-white cursor-pointer"
                            :class="breadcrumbsLinkClasses"
                        >
                            ...
                        </div>
                    </template>
                    <template #default>
                        <ul>
                            <li
                                v-for="modal in otherModals"
                                :key="modal.routeName"
                                class="text-sm text-black font-medium p-4 hover:bg-active-100"
                            >
                                <router-link :to="{ name: modal.routeName, params: modal.params }">
                                    {{ modal.breadcrumbName }}
                                </router-link>
                            </li>
                        </ul>
                    </template>
                </drop-menu>
            </div>
        </template>

        <div
            class="breadcrumbs__item breadcrumbs__item--active"
            :class="breadcrumbsItemClasses"
        >
            <span class="w-4 h-4 mx-1"><icon
                name="chevronRight"
                class="h-4 w-4"
            /></span>
            <div
                ref="lastBreadcrumb"
                class="breadcrumbs__link hover:text-white"
                :class="breadcrumbsLinkClasses"
                :title="getActiveBreadcrumbName"
            >
                {{ getActiveBreadcrumbName }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/Icon';
import ModalLink from '@/components/ui/modals/ModalLink';
import DropMenu from '@/components/ui/DropMenu';
import EventBus from '@/utils/EventBus';

export default {
    components: { DropMenu, Icon, ModalLink },

    props: {
        homeIcon: {
            type: String,
            default: 'home',
        },

        breadcrumbsLinkClasses: {
            type: String,
            default: '',
        },

        breadcrumbsItemClasses: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            needToCollapse: false,
        };
    },

    computed: {
        ...mapGetters({
            modals: 'modals/all',
            activeBreadcrumbName: 'modals/getActiveBreadcrumbName',
        }),

        getActiveBreadcrumbName() {
            return this.activeBreadcrumbName || this.$route.meta.breadcrumbName;
        },

        firstModal() {
            return this.modals?.[0];
        },

        otherModals() {
            return this.modals.slice(1);
        },
    },

    mounted() {
        this.checkIfCollapseRequired();
    },

    updated() {
        if (!this.needToCollapse) this.checkIfCollapseRequired();
    },

    methods: {
        checkIfCollapseRequired() {
            const { clientWidth, scrollWidth, innerText } = this.$refs.lastBreadcrumb;
            this.needToCollapse = clientWidth !== scrollWidth || (clientWidth === 0 && scrollWidth === 0 && innerText.trim().length > 0);
        },

        closeModalPopupComponents() {
            EventBus.emit('close-modal-file-manager');
            EventBus.emit('close-modal-colors-selector');
        },
    },
};
</script>

<style scoped>
.breadcrumbs {
    @apply flex font-frank font-medium;
    color: #c4c4c4;
}

.breadcrumbs__item {
    @apply flex text-xs;
}

.breadcrumbs__item--active {
    @apply overflow-hidden;
}

.breadcrumbs__item--active .breadcrumbs__link {
    @apply text-active-400 truncate;
}

.breadcrumbs--collapsed:deep(.drop-menu) {
    @apply p-0;
    top: 1.5rem;
    left: -1.5rem;
}
</style>
