import { createPermissionChecker, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        name: 'community_management.index',
        path: '/community-management/:customerId/community/:communityId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit community',
            breadcrumbName: 'edit community',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITY_DETAILS),
        },
    },
    {
        name: 'community_management.users.details',
        path: '/community-management/:customerId/community/:communityId/user/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/CommunityUserDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'user',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'community_management.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },

    {
        name: 'community_management.users.details.appPermissions',
        path: '/community-management/:customerId/community/:communityId/user/:profileId?/details/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'community_management.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'community_management.users.details.customization',
        path: '/community-management/:customerId/community/:communityId/user/:profileId/details/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'community_management.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'community_management.users.create',
        path: '/community-management/:customerId/community/:communityId/user/create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/CommunityUserCreate.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'add new user',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'community_management.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
        },
    },
    {
        name: 'community_management.users.create.appPermissions',
        path: '/community-management/:customerId/community/:communityId/user/create/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'community_management.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'community_management.users.create.customization',
        path: '/community-management/:customerId/community/:communityId/user/create/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'community_management.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'community_management.users.invite.index',
        path: '/community-management/:customerId/portfolio-users/:profileId/re-invite',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
];
