import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        path: '/presets/create',
        name: 'presets.create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new portfolio preset',
            breadcrumbName: 'add new',
            flowStartsWith: 'presets.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_PRESET),
        },
    },
    {
        path: '/presets/:presetId/clone',
        name: 'presets.clone',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'clone',
            title: 'clone preset',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'presets.index',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_PRESET),
            // custom props for this view only
            clone: true,
        },
    },
    {
        path: '/presets/:presetId',
        name: 'presets.edit',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit preset',
            breadcrumbName: 'edit',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: () =>
                createPermissionChecker(QUEXT_PERMISSIONS.EDIT_PRESET)() || createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PRESETS)(),
        },
    },
    {
        path: '/quext-presets/create',
        name: 'quextPresets.create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'add new quext preset',
            breadcrumbName: 'add new',
            flowStartsWith: 'presets.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_PRESET),
            // custom props for this view only
            quext: true,
        },
    },
    {
        path: '/quext-presets/:presetId/clone',
        name: 'quextPresets.clone',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'clone',
            title: 'clone preset',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'presets.index',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_PRESET),
            // custom props for this view only
            clone: true,
            quext: true,
        },
    },
    {
        path: '/quext-presets/:presetId',
        name: 'quextPresets.edit',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit preset',
            breadcrumbName: 'edit',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: () =>
                createPermissionChecker(QUEXT_PERMISSIONS.EDIT_PRESET)() || createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PRESETS)(),
            // custom props for this view only
            quext: true,
        },
    },
];
