import { createPermissionChecker, QUEXT_PERMISSIONS, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default [
    {
        name: 'application_mngmt.index',
        path: '/applications',
        component: TabbedRouterModal,
        meta: {
            loadInModal: true,
            nonStacked: true,
            breadcrumbName: 'applications',
            title: 'applications',
            product: 'qxt-admin',
            permissionChecker: () =>
                createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APPS)() || createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_BUNDLES)(),
        },
        children: [
            {
                name: 'applications.index',
                path: '/applications',
                component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/ApplicationsList'),
                meta: {
                    loadInModal: true,
                    actions: true,
                    title: 'applications',
                    flowStartsWith: 'applications.index',
                    product: 'qxt-admin',
                    permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APPS),
                },
            },
            {
                name: 'bundles.index',
                path: '/bundles',
                component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/bundles/BundlesList.vue'),
                meta: {
                    loadInModal: true,
                    actions: true,
                    title: 'bundles',
                    flowStartsWith: 'bundles.index',
                    product: 'qxt-admin',
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_BUNDLES),
                },
            },
        ],
    },
    // Bundles
    {
        path: '/bundles/create',
        name: 'bundles.create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/bundles/BundleCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new bundle',
            title: 'add new bundle',
            flowStartsWith: 'applications.index',
            hideFromSearch: true,
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_BUNDLE),
        },
    },
    {
        name: 'bundles.details',
        path: '/bundles/:id/details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/bundles/BundleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view bundle',
            title: 'view bundle',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_BUNDLE_DETAILS),
        },
    },
    // Application
    {
        path: '/applications/create',
        name: 'applications.create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/ApplicationsCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new application',
            breadcrumbName: 'add new',
            flowStartsWith: 'applications.create',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_APP),
        },
    },
    {
        name: 'applications.details',
        path: '/applications/:appId/details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/ApplicationDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view',
            title: 'view application',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_DETAILS),
        },
    },
    {
        path: '/applications/:appId/customers',
        name: 'applications.customers.index',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/customers/AppCustomersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'customer access',
            breadcrumbName: 'customer access',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
        },
    },
    {
        path: '/applications/:appId/customers/:customerId/:appAccessId/users',
        name: 'applications.customers.users',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/customers/AppCustomerUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'users',
            breadcrumbName: 'users',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
        },
    },
    //  Permissions
    {
        path: '/applications/:appId/permissions',
        name: 'applications.permissions.index',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/permissions/AppPermissionsList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'permissions',
            breadcrumbName: 'permissions',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_PERMISSIONS),
        },
    },
    {
        path: '/applications/:appId/permissions/:permissionId',
        name: 'applications.permissions.details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/permissions/AppPermissionDetails.vue'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'permissions details',
            breadcrumbName: 'permissions details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_PERMISSIONS),
        },
    },
    // Deleted applications
    {
        path: '/deleted-applications',
        name: 'applications.deletedApplications.index',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/DeletedApplicationsList'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted applications',
            breadcrumbName: 'deleted applications',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_APPS),
        },
    },
    {
        path: '/deleted-applications/:appId/details',
        name: 'applications.deletedApplications.details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/applications/DeletedApplicationDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'view application',
            breadcrumbName: 'view',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_APPS),
        },
    },
];
