import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default [
    {
        path: '/customers',
        name: 'customers.index',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customers/CustomersList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'customers',
            title: 'customers',
            flowStartsWith: 'customers.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMERS),
        },
    },
    {
        path: '/customers/create',
        name: 'customers.create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customers/CustomersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new customer',
            flowStartsWith: 'customers.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_CUSTOMER),
        },
    },
    {
        path: '/customers/:customerId/details',
        name: 'customers.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/CustomerDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'customer details',
            flowStartsWith: 'customers.index',
            title: 'view customer',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_DETAILS),
        },
    },
    // Communities
    {
        path: '/customers/:customerId/communities',
        name: 'customer.communities.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'communities',
            title: 'manage company communities',
            flowStartsWith: 'customers.index',
            passFlowTo: 'customer.communities.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            // custom
            quext: true,
        },
    },
    {
        path: '/customers/:customerId/communities/import',
        name: 'customer.communities.import',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesImportView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'import communities',
            title: 'import communities',
            flowStartsWith: 'customer.communities.index',
            passFlowTo: 'customer.communities.index',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.IMPORT_COMMUNITIES),
        },
    },
    {
        path: '/customers/:customerId/communities/create',
        name: 'customer.communities.create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new community',
            flowStartsWith: 'customers.index',
            passFlowTo: 'customer.communities.create',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_COMMUNITY),
        },
    },
    {
        path: '/customers/:customerId/communities/:communityId/details',
        name: 'customer.communities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community details',
            title: 'community details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITY_DETAILS),
        },
    },
    {
        path: '/customers/:customerId/communities/:communityId/user/:profileId/details',
        name: 'customer.communities.details.users.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/CommunityUserDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'user',
            product: 'qxt-admin',
            hideFromSearch: true,
            actions: true,
            flowStartsWith: 'customer.communities.details',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        path: '/customers/:customerId/communities/:communityId/user/:profileId/re-invite',
        name: 'customer.communities.details.users.invite.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'customer.communities.details.users.details.appPermissions',
        path: '/customers/:customerId/communities/:communityId/user/:profileId/details/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.communities.details',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.communities.details.users.details.customization',
        path: '/customers/:customerId/communities/:communityId/user/:profileId/details/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.communities.details',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },

    // Deleted communities
    {
        path: '/customers/:customerId/deleted-communities/:communityId/details',
        name: 'customer.deletedCommunities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'archived community details',
            breadcrumbName: 'archived community details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_COMMUNITIES),
        },
    },
    // Users
    {
        name: 'customer.users_mngmnt.index',
        path: '/customers/:customerId/users',
        component: TabbedRouterModal,
        meta: {
            loadInModal: true,
            nonStacked: true,
            breadcrumbName: 'users and roles management',
            title: 'users and roles management',
            product: 'qxt-admin',
            permissionChecker: () =>
                createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS)() ||
                createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS)(),
        },
        children: [
            {
                name: 'customer.admins.index',
                path: '/customers/:customerId/admins',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminsList.vue'),
                meta: {
                    title: 'portfolio admins',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
                },
            },
            {
                name: 'customer.users.index',
                path: '/customers/:customerId/users',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UsersList.vue'),
                meta: {
                    title: 'portfolio users',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
                },
            },
            {
                name: 'customer.roles.index',
                path: '/customers/:customerId/roles',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/roles/PortfolioRolesList.vue'),
                meta: {
                    title: 'portfolio roles',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
                },
            },
            // {
            //     name: 'customer.community_roles.index',
            //     path: '/customers/:customerId/community-roles',
            //     component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/roles/PortfolioRolesList.vue'),
            //     meta: {
            //         title: 'community roles',
            //         product: 'qxt-admin',
            //         hideFromSearch: true,
            //         permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            //     },
            // },
        ],
    },
    {
        name: 'customer.admins.details',
        path: '/customers/:customerId/admins/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'admins',
            title: 'admin',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    {
        name: 'customer.admins.create',
        path: '/customers/:customerId/admins/create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminCreate.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'admins',
            title: 'add new admin',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_PORTFOLIO_ADMIN),
        },
    },
    {
        name: 'customer.admins.invite.index',
        path: '/customers/:customerId/admins/:profileId/re-invite',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'customer.admins.details.appPermissions',
        path: '/customers/:customerId/admins/:profileId/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.admins.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    {
        name: 'customer.users.details',
        path: '/customers/:customerId/users/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.edit',
        path: '/customers/:customerId/users/:profileId/edit',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAccessEdit.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'edit user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.EDIT_CUSTOMER_USER),
        },
    },
    {
        name: 'customer.users.create',
        path: '/customers/:customerId/users/create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserCreate.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'add new user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
        },
    },
    {
        name: 'customer.users.invite.index',
        path: '/customers/:customerId/users/:profileId/re-invite',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'customer.users.details.appPermissions',
        path: '/customers/:customerId/portfolio-users/:profileId/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.details.customization',
        path: '/customers/:customerId/portfolio-users/:profileId/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.edit.appPermissions',
        path: '/customers/:customerId/portfolio-users/:profileId/edit/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.edit.customization',
        path: '/customers/:customerId/portfolio-users/:profileId/edit/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.create.appPermissions',
        path: '/customers/:customerId/portfolio-users/create/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.create.customization',
        path: '/customers/:customerId/portfolio-users/create/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.edit.appPermissions',
        path: '/customers/:customerId/portfolio-users/:profileId/edit/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.users.create.appPermissions',
        path: '/customers/:customerId/portfolio-users/create/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'customer.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'customer.roles.details',
        path: '/customers/:customerId/roles/:roleId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/roles/PortfolioRoleDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'roles',
            title: 'portfolio role',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },

    // Deleted customers
    {
        path: '/deleted-customers/:customerId/details',
        name: 'deletedCustomers.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/CustomerDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted customer details',
            breadcrumbName: 'deleted customer details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_CUSTOMERS),
            // custom props for this view only
            deactivatedCustomer: true,
        },
    },
    {
        path: '/deleted-customers/:customerId/communities',
        name: 'deletedCustomer.communities.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'communities',
            title: 'view company communities',
            flowStartsWith: 'customers.index',
            passFlowTo: 'deletedCustomer.communities.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            // custom props for this view only
            deactivatedCustomer: true,
            quext: true,
        },
    },
    {
        path: '/deleted-customers/:customerId/communities/:communityId/details',
        name: 'deletedCustomer.communities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'community details',
            title: 'community details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITY_DETAILS),
            // custom props for this view only
            deactivatedCustomer: true,
        },
    },
];
