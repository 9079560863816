import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import { resources } from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import { getReqContextHeaders } from '@/utils/auth';

const baseURL = getEnvVariable('VUE_APP_AUTH_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => ({
    ...requestConfig,
    headers: {
        ...getReqContextHeaders(),
        ...requestConfig.headers,
    },
});

const afterRequestInterceptor = ({ response }) => {
    if (!response) {
        // TODO: No response is returned when the request is sent and the token is expired at the same time but the request is not failed
        return {};
    }

    return response.data;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor, afterRequestInterceptor);

export default new DataProvider(httpAdapter);
