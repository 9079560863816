import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        name: 'superAdmins.create',
        path: '/super-admins/create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/superadmins/SuperAdminCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new super admin',
            flowStartsWith: 'superAdmins.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.invite.index',
        path: '/super-admins/:profileId/reinvite',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/superadmins/SuperAdminReinviteView'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.details',
        path: '/super-admins/:id/details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/superadmins/SuperAdminDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit',
            title: 'edit super admin',
            flowStartsWith: 'superAdmins.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
    },
];
