import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';
import store from '@/store';

const pathProps = () => {
    const { customerId } = store.getters['auth/profile'];
    return { customerId };
};

const searchFilter = () => {
    const profile = store.getters['auth/profile'];
    return profile?.customerId;
};

export default [
    {
        path: '/company/:customerId',
        name: 'company.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/CustomerDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'company details',
            breadcrumbName: 'company',
            flowStartsWith: 'company.index',
            product: 'admin',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_DETAILS),
            pathProps,
            searchFilter,
        },
    },
    // Communities
    {
        path: '/company/:customerId/communities',
        name: 'company.communities.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage communities',
            breadcrumbName: 'communities',
            flowStartsWith: 'company.communities.index',
            product: 'admin',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/communities/import',
        name: 'company.communities.import',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesImportView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'import communities',
            title: 'import communities',
            flowStartsWith: 'company.communities.index',
            passFlowTo: 'company.communities.index',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.IMPORT_COMMUNITIES),
        },
    },
    {
        path: '/company/:customerId/communities/create',
        name: 'company.communities.create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunitiesCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new community',
            breadcrumbName: 'add new community',
            flowStartsWith: 'company.communities.create',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_COMMUNITY),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/details',
        name: 'company.communities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community details',
            title: 'community details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITY_DETAILS),
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/user/:profileId/details',
        name: 'company.communities.details.users.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/CommunityUserDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'user',
            product: 'qxt-admin',
            hideFromSearch: true,
            actions: true,
            requiresCustomer: true,
            flowStartsWith: 'company.communities.details',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        path: '/company/:customerId/communities/:communityId/user/:profileId/re-invite',
        name: 'company.communities.details.users.invite.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'company.communities.details.users.details.appPermissions',
        path: '/company/:customerId/communities/:communityId/user/:profileId/details/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'company.communities.details',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'company.communities.details.users.details.customization',
        path: '/company/:customerId/communities/:communityId/user/:profileId/details/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'company.communities.details',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    // Deleted communities
    {
        path: '/company/:customerId/deleted-communities/:communityId/details',
        name: 'company.deletedCommunities.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/communities/CommunityDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted community details',
            breadcrumbName: 'deleted community details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_COMMUNITIES),
        },
    },
    // Roles
    {
        path: '/company/:customerId/roles',
        name: 'company.roles.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/RolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage company roles',
            breadcrumbName: 'roles',
            flowStartsWith: 'company.roles.index',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/roles/:roleId/details',
        name: 'company.roles.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'role details',
            breadcrumbName: 'role details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
        },
    },
    // Deleted Roles
    {
        path: '/company/:customerId/deleted-roles',
        name: 'company.deletedRoles.index',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/DeletedRolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted company roles',
            breadcrumbName: 'deleted roles',
            flowStartsWith: 'company.roles.index',
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            pathProps,
            searchFilter,
        },
    },
    {
        path: '/company/:customerId/deleted-roles/:roleId/details',
        name: 'company.deletedRoles.details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/customers/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'deleted role details',
            breadcrumbName: 'deleted role details',
            hideFromSearch: true,
            requiresCustomer: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
        },
    },
];
