import { computed } from 'vue';
import store from '@/store';
import { QUEXT_PERMISSIONS, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions/constants';
import { useContextParams } from '@/stores/auth/contextParams';

export const useAuthPermissions = () => {
    const hasAuthPermission = computed(() => (permissionName, customerIdVal, communityIdVal) => {
        const { context } = useContextParams();
        const hasAuthAccess = store.getters['auth/hasAuthAccess'];

        const customerId = customerIdVal || context.customerId || store.getters['auth/customerId'];
        const communityId = communityIdVal || context.communityId || store.getters['auth/community']?.id;

        if (!hasAuthAccess) {
            return false;
        }

        const getPermission = store.getters['auth/getAuthPermissions'];

        if (Array.isArray(permissionName)) {
            return permissionName.some(name => getPermission(name, customerId, communityId)?.value === 'true');
        }

        return getPermission(permissionName, customerId, communityId)?.value === 'true';
    });

    return {
        QUEXT_PERMISSIONS,
        PORTFOLIO_PERMISSIONS,
        hasAuthPermission,
    };
};
