import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default [
    {
        name: 'quext_users_roles_mngmt',
        path: '/quext-users-roles',
        component: TabbedRouterModal,
        meta: {
            loadInModal: true,
            nonStacked: true,
            breadcrumbName: 'quext users and roles management',
            title: 'quext users and roles management',
            product: 'qxt-admin',
            permissionChecker: () =>
                createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN)() ||
                createPermissionChecker(QUEXT_PERMISSIONS.VIEW_QUEXT_USERS)() ||
                createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PRESETS),
        },
        children: [
            {
                name: 'superAdmins.index',
                path: '/super-admins',
                component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/superadmins/SuperAdminsList'),
                meta: {
                    loadInModal: true,
                    title: 'super admins',
                    flowStartsWith: 'superAdmins.index',
                    product: 'qxt-admin',
                    permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
                },
            },
            {
                name: 'quextUsers.index',
                path: '/quext-users',
                component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/quextUsers/QuextUsersList'),
                meta: {
                    loadInModal: true,
                    title: 'quext users',
                    flowStartsWith: 'quextUsers.index',
                    product: 'qxt-admin',
                    permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_QUEXT_USERS),
                },
            },
            {
                path: '/quext-presets',
                name: 'quextPresets.index',
                component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/presets/PresetsList'),
                meta: {
                    loadInModal: true,
                    title: 'quext presets',
                    flowStartsWith: 'quextPresets.index',
                    product: 'qxt-admin',
                    permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PRESETS),
                },
            },
        ],
    },
];
