import { ref } from 'vue';
import { useStore } from 'vuex';
import FilemanagerDataProvider from '@/api/filemanager';

export const useCommunityFileLibrary = () => {
    const loading = ref(false);

    const store = useStore();

    const loadFilesToStore = data => store.dispatch('files/loadLibrary', data);
    const clearLibraryStore = () => store.dispatch('files/clearLibrary');

    const loadLibrary = async communityId => {
        try {
            loading.value = true;
            const data = await FilemanagerDataProvider.adapter.get('communityFiles', { communityId });

            const files = data.map(file => ({
                base_directory: file.base_directory,
                original_name: file.file_name,
                file_uuid: file.file_uuid,
                thumbnail_url: file.thumbnail_url,
                file_url: file.file_url,
                file_type: file.file_type,
                file_size: file.file_size,
                created_at: file.created_at,
                updated_at: file.updated_at,
                tags: [],
            }));

            await loadFilesToStore(files);
        } finally {
            loading.value = false;
        }
    };

    return {
        loading,
        loadLibrary,
        clearLibraryStore,
    };
};
