<template>
    <div
        class="alert"
        :class="`alert--${theme}`"
    >
        <!-- @slot Alert message content -->
        <div class="alert__content">
            <slot />
        </div>
        <div
            class="alert__progress-bar"
            :class="`alert__progress-bar--${theme}`"
        >
            <div
                :key="closeTime"
                class="alert__progress-bar-fill"
                :class="`alert__progress-bar-fill--${theme}`"
                :style="{ animationDuration: closeTime + 'ms' }"
            />
        </div>
        <div
            class="alert__close"
            @click="$emit('close')"
        >
            <Icon
                name="close"
                class="h-3 w-3"
            />
        </div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';
import { ALERT_TYPE } from '@/utils/constants/common/alert';

export default {
    components: { Icon },

    props: {
        /**
         * Alert message theme
         */
        theme: {
            type: String,
            default: ALERT_TYPE.SUCCESS,
            validation: function (value) {
                return Object.values(ALERT_TYPE).indexOf(value) !== -1;
            },
        },

        /**
         * Alert closing timer for progress bar
         */
        closeTime: {
            type: Number,
            default: 0,
        },
    },

    emits: [
        /**
         * Emitted on close icon click
         */
        'close',
    ],
};
</script>

<style scoped>
.alert {
    @apply fixed top-3 right-3 text-sm font-500 font-frank bg-transparent border border-transparent;
    max-width: 35ch;
    z-index: 1000000;
    /* transform: translateY(-130%); */
}

.alert__content {
    @apply p-8;
}

.alert--success {
    @apply bg-leaf-150 border-leaf-500 text-leaf-700;
}

.alert--error {
    @apply bg-red-100 border-red-350 text-red-800;
}

.alert--info {
    @apply bg-blue-100 border-blue-500 text-blue-800;
}

.alert__close {
    @apply absolute top-2 right-2 w-2 h-2 cursor-pointer;
}

.alert__progress-bar {
    position: relative;
    height: 4px;
    overflow: hidden;
}

.alert__progress-bar--success {
    background-color: theme('colors.leaf.150');
}

.alert__progress-bar--error {
    background-color: theme('colors.red.100');
}

.alert__progress-bar--info {
    background-color: theme('colors.blue.100');
}

.alert__progress-bar-fill {
    height: 100%;
    animation-name: progressBarFill;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.alert__progress-bar-fill--success {
    background-color: theme('colors.leaf.700');
}

.alert__progress-bar-fill--error {
    background-color: theme('colors.red.800');
}

.alert__progress-bar-fill--info {
    background-color: theme('colors.blue.800');
}

@keyframes progressBarFill {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}
</style>
