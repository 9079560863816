import { createPermissionChecker, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'company.index',
        items: [
            {
                title: 'manage communities',
                routeName: 'company.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
        ],
    },
    {
        routeName: 'deactivatedCompany.index',
        items: [
            {
                title: 'view communities',
                routeName: 'company.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
        ],
    },
    // Communities
    {
        routeName: 'company.communities.index',
        items: [],
    },
    {
        routeName: 'company.community.create',
        items: [],
    },
];
