import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';
import { AUTH_APP_ID } from '@/components/auth/constants';

export default [
    {
        name: 'customer_roles_mngmt',
        path: '/customer-roles',
        component: TabbedRouterModal,
        meta: {
            loadInModal: true,
            nonStacked: true,
            breadcrumbName: 'customer roles management',
            title: 'customer roles management',
            product: 'qxt-admin',
            permissionChecker: () =>
                createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PERMISSION_GROUPS)() ||
                createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES)(),
        },
        children: [
            {
                name: 'permission_groups.index',
                path: '/customer-roles/permission-groups',
                component: () =>
                    import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/permission_groups/ServicesList.vue'),
                meta: {
                    title: 'permissions groups',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PERMISSION_GROUPS),
                },
            },
            {
                name: 'metaroles.index',
                path: '/customer-roles/metaroles',
                component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetarolesList.vue'),
                meta: {
                    title: 'meta roles',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
                },
            },
        ],
    },
    // temp solution for AUTH permission-groups (waiting for permission-groups v2)
    {
        name: 'permission_groups.details.auth',
        path: `/customer-roles/services/${AUTH_APP_ID}/permission-groups`,
        component: () =>
            import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/permission_groups/AuthPermissionGroups.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions groups',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PERMISSION_GROUPS),
        },
    },
    {
        name: 'permission_groups.details',
        path: '/customer-roles/services/:appId/permission-groups',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/permission_groups/PermissionGroups.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions groups',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_PERMISSION_GROUPS),
        },
        // temp solution for AUTH permission-groups (waiting for permission-groups v2)
        beforeEnter: (to, from, next) => {
            if (to.params.appId === AUTH_APP_ID) {
                return next({
                    name: 'permission_groups.details.auth',
                });
            }

            next();
        },
    },
    {
        name: 'metaroles.create',
        path: '/customer-roles/metaroles/create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetarolesCreate.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'meta roles',
            title: 'add new meta role',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_METAROLE),
        },
    },
    {
        name: 'metaroles.create.customization',
        path: '/customer-roles/metaroles/new/app/:appId/customization',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetaroleAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'application name',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'metaroles.index',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_METAROLE),
        },
    },
    {
        name: 'metaroles.create.appPermissions',
        path: '/customer-roles/metaroles/new/app/:appId/permissions',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetaroleAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'application name (v1)',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'metaroles.index',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_METAROLE),
        },
    },
    {
        name: 'metaroles.details',
        path: '/customer-roles/metaroles/:id/details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetaroleDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'meta role details',
            title: 'meta roles',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
        },
    },
    {
        name: 'metaroles.details.customization',
        path: '/customer-roles/metaroles/:id/app/:appId/customization',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetaroleAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'application name',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'metaroles.index',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_METAROLE),
        },
    },
    {
        name: 'metaroles.details.appPermissions',
        path: '/customer-roles/metaroles/:id/app/:appId/permissions',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/customer_roles/metaroles/MetaroleAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'application name (v1)',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'metaroles.index',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
        },
    },
];
