<template>
    <div>
        <ContextSelect
            ref="communitySelector"
            v-model="customerCommunity"
            class="community-context-selector"
            :options="options"
            track-by="id"
            label="name"
            placeholder="select community"
            :disabled="disabled"
            :no-clear="!community"
        >
            <template #empty-result>
                {{ options.length === 0 ? 'No communities available' : 'No results found matching your search' }}
            </template>
        </ContextSelect>
    </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin';
import { useAlertStore } from '@/stores/alert';
import ContextSelect from '@/components/auth/context_select/ContextSelect';
import EventBus from '@/utils/EventBus';
import { REFRESH_PERMISSIONS_EVENT } from '@/components/permission_service/constants';
import { useCommunityFileLibrary } from '@/components/auth/useCommunityFileLibrary';

export default {
    name: 'CommunitySelect',
    components: {
        ContextSelect,
    },

    mixins: [AuthMixin],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const { notifyError } = useAlertStore();

        const communityLibrary = useCommunityFileLibrary();

        return { notifyError, communityLibrary };
    },

    data() {
        return {
            customerCommunity: null,
            communitiesUnsubscribe: null,
            options: [],
        };
    },

    watch: {
        'profile.customerId': function (val) {
            if (val) {
                this.fetchCommunities();
            } else {
                this.options = [];
                this.customerCommunity = null;
            }
        },

        community: {
            handler: function (community) {
                if (community) {
                    this.fetchCommunityAppCodes(community.id);
                } else {
                    this.setCustomerCommunityAppCodes([]);
                }
            },
        },

        customerCommunity(value = null) {
            this.changeCommunity(value);
            this.$refs.communitySelector.search = null;

            EventBus.emit(REFRESH_PERMISSIONS_EVENT);
        },
    },

    mounted() {
        if (this.profile.customerId) {
            this.fetchCommunities();
        }
    },

    methods: {
        async fetchCommunities() {
            try {
                const response = await this.$authDataProvider.get('userCommunities', { size: 999, nonDeleted: true, sort: 'name,asc' });
                this.options = response.content.map(({ id, name, timezoneId }) => ({ id, name, timezoneId }));

                this.setCommunities(this.options);

                if (this.options.length === 1) {
                    this.customerCommunity = this.options[0];
                } else if (!this.community?.id) {
                    this.customerCommunity = null;
                } else {
                    this.customerCommunity = this.options.find(({ id }) => id === this.community.id);
                }
            } catch (e) {
                this.notifyError('Failed to fetch data for communities selector');
            }
        },

        async fetchCommunityAppCodes(communityId) {
            try {
                const response = await this.$authDataProvider.get('communityLicensedAppAccess', {
                    customerId: this.profile.customerId,
                    communityId,
                });

                this.setCustomerCommunityAppCodes(response.content.map(app => app.appCode));
            } catch (e) {
                this.notifyError('Failed to fetch applications authorized for the selected community');
            }
        },

        changeCommunity(community) {
            this.setCommunity(community);

            if (community) {
                try {
                    this.communityLibrary.loadLibrary(community.id);
                } catch (error) {
                    console.error(error);
                }
            } else {
                this.communityLibrary.clearLibraryStore();
            }
        },
    },
};
</script>

<style scoped>
.community-context-selector {
    &:deep(.context-selector__input) {
        @apply font-700;

        &::placeholder {
            @apply font-700;
        }
    }
}
</style>
