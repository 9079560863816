import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        name: 'quextUsers.details',
        path: '/quext-users/:id/details',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/quextUsers/QuextUserDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'view',
            title: 'view quext user',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_QUEXT_USER_DETAILS),
        },
    },
    {
        name: 'quextUsers.details.permissions',
        path: '/quext-users/:id/permissions',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/quextUsers/QuextUserCustomPermissions'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'customize permissions',
            title: 'custom permissions',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_QUEXT_USER),
        },
    },
    {
        name: 'quextUsers.create',
        path: '/quext-users/create',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/quextUsers/QuextUsersCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new quext user',
            flowStartsWith: 'quextUsers.create',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_QUEXT_USER),
        },
    },
    {
        name: 'quextUsers.create.permissions',
        path: '/quext-users/permissions',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/quextUsers/QuextUserCustomPermissions'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'customize permissions',
            title: 'custom permissions',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_QUEXT_USER),
        },
    },
    {
        path: '/quext-users/:profileId/reinvite',
        name: 'quextUsers.invite.index',
        component: () => import(/* webpackChunkName: "qxtsetup" */ '@/views/auth/quextUsers/QuextUserReinviteView'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.REINVITE_QUEXT_USER),
        },
    },
];
