export const BUNDLE_TYPES = {
    ALL: 'ALL',
    SUITE: 'SUITE',
};

export const AUTH_ALIAS = 'AUTH';
// TODO: check if localization is needed
export const AUTH_NAME = 'administrative setup';

export const AUTH_APP_ID = '00000000-0000-0000-0000-000000000000';

export const NO_ROLE = {
    key: 'NO_ROLE',
    value: 'No role',
};

export const PORTFOLIO_ADMIN = {
    id: 'pa_preset',
    name: 'Portfolio Admin',
    description: 'All portfolio permissions',
    roleId: '00000000-0000-0000-0000-000000000001',
    groupId: '00000000-0000-0000-0000-000000000002',
};

export const ROLES = {
    CA: 'CUSTOMER_ADMIN',
    CA_READONLY: 'CUSTOMER_READONLY_ADMIN',
    USER: 'USER',
    NO_ROLE: 'NO_ROLE',
};

export const QUEXT_ROLES = [
    {
        key: 'SUPER_ADMIN',
        value: 'SUPER_ADMIN',
    },
    {
        key: 'SUPER_READONLY_ADMIN',
        value: 'SUPER_READONLY_ADMIN',
    },
    {
        key: 'DIGITAL_HUMAN_WRITER',
        value: 'DIGITAL_HUMAN_WRITER',
    },
    {
        key: 'ADMIN',
        value: 'ADMIN',
    },
];

export const USER_CATEGORIES = {
    SUPER_ADMIN: {
        type: 'SUPER_ADMIN',
        alias: 'super admin',
    },
    Q_USER: {
        type: 'QUEXT',
        alias: 'quext user',
    },
    P_ADMIN: {
        type: 'PORTFOLIO_ADMIN',
        alias: 'portfolio admin',
    },
    P_USER: {
        type: 'PORTFOLIO',
        alias: 'portfolio user',
    },
    USER: {
        type: 'USER',
        alias: 'user',
    },
};

export const USER_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    INVITED: 'INVITED',
    DELETED: 'DELETED',
};

export const STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    INVITED: 'invited',
    DELETED: 'deleted',
};

export const STATUS_OPTIONS = [
    { key: 'ACTIVE', value: 'active' },
    { key: 'INACTIVE', value: 'inactive' },
    { key: 'INVITED', value: 'invited' },
    { key: 'DELETED', value: 'deleted' },
];

export const PRESET_CATEGORIES = {
    QUEXT: 'QUEXT',
    PORTFOLIO: 'PORTFOLIO',
};

export const SYSTEM_PRESET_NAMES = {
    SUPER_ADMIN: 'super admin',
    PORTFOLIO_ADMIN: 'portfolio admin',
};

export const SYNC_OPTIONS = [
    {
        type: 'CUSTOMERS',
        name: 'SyncCustomers',
        description: 'The App wants to receive data about existing and active Customers',
    },
    {
        type: 'USERS',
        name: 'SyncUsers',
        description: 'The App wants to receive data about existing and active Identities',
    },
    {
        type: 'USER_DATA',
        name: 'SyncUserData',
        description: 'The App wants to receive data about existing and active customer user profiles',
    },
    {
        type: 'AUTO_AUTHORIZE_CUSTOMERS',
        name: 'AutoAuthorizeCustomers',
        description:
            'The App wants to be automatically authorized to all existing and future customers (effectively excluding itself from bundle management)',
    },
];
