import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'presets.index',
        items: [],
    },
    {
        routeName: 'presets.create',
        items: [],
    },
    {
        routeName: 'quextPresets.edit',
        items: [
            {
                title: 'copy as new preset',
                routeName: 'quextPresets.clone',
                params: {
                    presetId: '{presetId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_PRESET),
            },
        ],
    },
    {
        routeName: 'presets.edit',
        items: [
            {
                title: 'copy as new preset',
                routeName: 'presets.clone',
                params: {
                    presetId: '{presetId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_PRESET),
            },
        ],
    },
];
