import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'applications.details',
        items: [
            {
                title: 'view permissions',
                routeName: 'applications.permissions.index',
                params: {
                    appId: '{appId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_PERMISSIONS),
            },
            {
                title: 'customer access',
                routeName: 'applications.customers.index',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
            },
        ],
    },
    {
        routeName: 'applications.permissions.index',
        items: [],
    },
    {
        routeName: 'applications.customers.users.details',
        items: [],
    },
    {
        routeName: 'applications.bundles.details',
        items: [],
    },
];
