import { createPermissionChecker, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default [
    {
        name: 'users_mngmt',
        path: '/company/:customerId/portfolio-users',
        component: TabbedRouterModal,
        meta: {
            loadInModal: true,
            nonStacked: true,
            breadcrumbName: 'users and roles management',
            title: 'users and roles management',
            product: 'qxt-admin',
            permissionChecker: () =>
                createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS)() ||
                createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS)(),
        },
        children: [
            {
                name: 'portfolio.admins.index',
                path: '/company/:customerId/portfolio-admins',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminsList.vue'),
                meta: {
                    title: 'portfolio admins',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
                },
            },
            {
                name: 'portfolio.users.index',
                path: '/company/:customerId/portfolio-users',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UsersList.vue'),
                meta: {
                    title: 'portfolio users',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
                },
            },
            {
                name: 'portfolio.roles.index',
                path: '/company/:customerId/portfolio-roles',
                component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/roles/PortfolioRolesList.vue'),
                meta: {
                    title: 'portfolio roles',
                    product: 'qxt-admin',
                    hideFromSearch: true,
                    permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
                },
            },
            // {
            //     name: 'portfolio.community_roles.index',
            //     path: '/company/:customerId/portfolio-community-roles',
            //     component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/roles/PortfolioRolesList.vue'),
            //     meta: {
            //         title: 'community roles',
            //         product: 'qxt-admin',
            //         hideFromSearch: true,
            //         permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            //     },
            // },
        ],
    },
    {
        name: 'portfolio.admins.details',
        path: '/company/:customerId/portfolio-admins/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'admins',
            title: 'admin',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    {
        name: 'portfolio.admins.create',
        path: '/company/:customerId/portfolio-admins/create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminCreate.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'admins',
            title: 'add new admin',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_PORTFOLIO_ADMIN),
        },
    },
    {
        name: 'portfolio.admins.invite.index',
        path: '/company/:customerId/portfolio-admins/:profileId/re-invite',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'portfolio.admins.details.appPermissions',
        path: '/company/:customerId/portfolio-admins/:profileId/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.admins.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    {
        name: 'portfolio.users.details',
        path: '/company/:customerId/portfolio-users/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.users.edit',
        path: '/company/:customerId/portfolio-users/:profileId/edit',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAccessEdit.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'edit user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.EDIT_CUSTOMER_USER),
        },
    },
    {
        name: 'portfolio.users.edit.appPermissions',
        path: '/company/:customerId/portfolio-users/:profileId/edit/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.users.edit.customization',
        path: '/company/:customerId/portfolio-users/:profileId/edit/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.users.create',
        path: '/company/:customerId/portfolio-users/create',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserCreate.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'add new user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
        },
    },
    {
        name: 'portfolio.users.create.appPermissions',
        path: '/company/:customerId/portfolio-users/create/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.users.create.customization',
        path: '/company/:customerId/portfolio-users/create/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.users.invite.index',
        path: '/company/:customerId/portfolio-users/:profileId/re-invite',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserReinvite.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.REINVITE_CUSTOMER_USER),
        },
    },
    {
        name: 'portfolio.users.details.appPermissions',
        path: '/company/:customerId/portfolio-users/:profileId/permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppPermissions.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'permissions',
            title: 'permissions',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.users.details.customization',
        path: '/company/:customerId/portfolio-users/:profileId/app/:appId/customize-permissions',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserAppCustomization.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'customization',
            title: 'customization',
            product: 'qxt-admin',
            hideFromSearch: true,
            flowStartsWith: 'portfolio.users.index',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.roles.details',
        path: '/company/:customerId/portfolio-roles/:roleId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/roles/PortfolioRoleDetails.vue'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'roles',
            title: 'portfolio role',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    // {
    //     name: 'portfolio.roles.create',
    //     path: '/company/:customerId/portfolio-roles/create',
    //     component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminDetails.vue'),
    //     meta: {
    //         loadInModal: true,
    //         breadcrumbName: 'roles',
    //         title: 'add new role',
    //         product: 'qxt-admin',
    //         hideFromSearch: true,
    //         permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_PORTFOLIO_ADMIN),
    //     },
    // },
    // DELETED ENTITIES
    {
        name: 'portfolio.deleted_admins.index',
        path: '/company/:customerId/deleted-portfolio-admins',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminsList.vue'),
        meta: {
            deletedEntities: true,
            title: 'portfolio admins',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    {
        name: 'portfolio.deleted_admins.details',
        path: '/company/:customerId/deleted-portfolio-admins/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/admins/AdminDetails.vue'),
        meta: {
            deletedEntities: true,
            loadInModal: true,
            breadcrumbName: 'admins',
            title: 'admin',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
        },
    },
    {
        name: 'portfolio.deleted_users.index',
        path: '/company/:customerId/deleted-portfolio-users',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UsersList.vue'),
        meta: {
            deletedEntities: true,
            title: 'portfolio users',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
    {
        name: 'portfolio.deleted_users.details',
        path: '/company/:customerId/deleted-portfolio-users/:profileId/details',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/auth/users_management/users/UserDetails.vue'),
        meta: {
            deletedEntities: true,
            loadInModal: true,
            breadcrumbName: 'users',
            title: 'user',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
        },
    },
];
