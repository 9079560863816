import { useContextParams } from '@/stores/auth/contextParams';

// a method for setting headers based on the context of a customer and community,
// needed to validate permissions within that customer and community.
export const getReqContextHeaders = () => {
    const { context } = useContextParams();
    const res = {};

    if (context.customerId) {
        res['X-Quext-Customer-Id'] = context.customerId;
    }

    if (context.communityId) {
        res['X-Quext-Community-Id'] = context.communityId;
    }

    return res;
};
